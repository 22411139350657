const Noodl = require("@noodl/noodl-sdk");
const XLSX = require("xlsx");

const ArraysToExcelNode = Noodl.defineNode({
	category: "My Utils",
	name: "ArraysToExcelNode",
	inputs: {
		systemsArray: "array",
		integrationsArray: "array",
		FileName: "string",
	},
	signals: {
		exportAsExcel: function () {
			let workbook = XLSX.utils.book_new();
			let systemsArray = this.inputs.systemsArray.map((item) => {
				if (Array.isArray(item.Integrasjoner)) {
					item.Integrasjoner = item.Integrasjoner.join("\n");
				}
				return item;
			});
			let worksheet = XLSX.utils.json_to_sheet(systemsArray);
			XLSX.utils.book_append_sheet(workbook, worksheet, "XO-Build");
			XLSX.writeFile(workbook, this.inputs.FileName || "output.xlsx");
		},
	},
});

Noodl.defineModule({
	nodes: [ArraysToExcelNode],
	setup() {
		console.log("Module setup");
	},
});
